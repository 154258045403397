<script lang="ts" setup>
import InfoModal from '@exchange/libs/modals/src/lib/common/InfoModal.vue';

const emit = defineEmits<{
  (e: 'contact-support'): void;
  (e: 'logout'): void;
}>();
</script>

<template>
  <info-modal
    testid="uk-certification-not-eligible-modal"
    content-test-id="uk-certification-not-eligible-modal-content"
    variant="dark"
    :no-icon="true"
    title="ui.ukSelfCertificationNotEligible.title"
    :message="$t('ui.ukSelfCertificationNotEligible.message')"
    action="ui.ukSelfCertificationNotEligible.primaryAction"
    action-test-id="contact-support"
    action-secondary="modules.auth.logout"
    action-secondary-test-id="logout"
    @action-clicked="emit('contact-support')"
    @action-secondary-clicked="emit('logout')"
  />
</template>
